import { colors } from '@/styles/colors';

export const PopoverStyles = {
  baseStyle: {
    popper: {
      zIndex: 20,
    },
    content: {
      borderRadius: '16px',
      boxShadow: '0px 0px 24px 0px #D0D2D84D',
      _focus: {
        boxShadow: '0px 0px 24px 0px #D0D2D84D',
      },
    },
  },
  variants: {
    deepWriter: () => ({
      content: {
        width: '228px',
        borderRadius: '16px',
      },
      body: {
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        gap: '16px',
      },
    }),
    unstyled: () => ({
      popper: {
        zIndex: 210,
      },
      content: {
        padding: '0',
        border: 'none',
        width: 'auto',
        height: 'auto',
        borderRadius: '0',
        background: 'transparent',
        boxShadow: 'none',
      },
      body: {
        padding: '0',
        border: 'none',
        width: 'auto',
        height: 'auto',
        borderRadius: '0',
        background: 'transparent',
        boxShadow: 'none',
      },
    }),
    primary: () => ({
      content: {
        maxWidth: '350px',
        border: '2px solid',
        borderRadius: '16px',
        boxShadow: '0px 0px 24px 0px #D0D2D84D',
        borderColor: `${colors.brand.gray25}`,
      },
      body: {
        padding: '16px',
      },
    }),
    colorsPicker: () => ({
      popper: {
        zIndex: 210,
      },
      content: {
        maxWidth: '350px',
        border: '2px solid',
        borderRadius: '16px',
        boxShadow: '0px 0px 24px 0px #D0D2D84D',
        borderColor: `${colors.brand.gray25}`,
      },
      body: {
        padding: '16px',
      },
    }),
  },
};
