// colors.ts

import { avatarBorderByExamCode } from './constants';

export const colors = {
  brand: {
    gray1: 'rgb(51, 56, 62)',
    gray2: 'rgb(71, 76, 81)',
    gray3: 'rgb(97, 97, 97)',
    gray4: 'rgb(146, 148, 158)',
    gray5: 'rgb(233, 234, 237)',
    gray6: 'rgb(242, 243, 247)',
    gray7: 'rgb(244, 247, 249)',
    gray10: 'rgba(252, 252, 252, 1)',
    blue: 'rgb(62, 128, 191)',
    bluePrimary: 'rgb(20, 110, 245)',
    bluePrimary50: 'rgb(218, 232, 253)',
    bluePrimary600: 'rgb(9, 92, 215)',
    bluePrimary700: 'rgb(7, 75, 176)',
    red: 'rgba(255,0,0)',
    orange: 'rgb(245, 148, 50)',
    green: 'rgb(76, 144, 127)',
    purple: 'rgb(147, 98, 176)',
    purple10: 'rgb(249, 244, 253)',
    purple800: 'rgb(136, 43, 214)',
    white: 'rgb(255, 255, 255)',
    black: 'rgb(0, 0, 0)',
    gray25: 'rgb(240,244,248)',
    gray50: 'rgb(235, 238, 242)',
    gray100: 'rgb(225, 230, 236)',
    gray200: 'rgb(215, 221, 229)',
    gray300: 'rgb(205, 213, 223)',
    gray400: 'rgb(185, 192, 201)',
    gray500: 'rgb(144, 149, 156)',
    gray600: 'rgb(103, 107, 112)',
    gray700: 'rgb(61, 64, 67)',
    gray800: 'rgb(41, 43, 45)',
    gray900: 'rgb(20, 21, 22)',
    primary25: 'rgb(253, 240, 230)',
    primary50: 'rgb(252, 225, 204)',
    primary100: 'rgb(250, 210, 179)',
    primary200: 'rgb(249, 195, 153)',
    primary300: 'rgb(247, 181, 128)',
    primary400: 'rgb(245, 166, 103)',
    primary500: 'rgb(244, 151, 77)',
    primary600: 'rgb(242, 136, 52)',
    primary700: 'rgb(239, 106, 1)',
    primary800: 'rgb(215, 95, 1)',
    primary900: 'rgb(191, 85, 1)',
    error25: 'rgb(253, 235, 234)',
    error50: 'rgb(251, 215, 213)',
    error100: 'rgb(249, 195, 192)',
    error200: 'rgb(247, 175, 171)',
    error300: 'rgb(246, 155, 150)',
    error400: 'rgb(244, 135, 129))',
    error500: 'rgb(242, 115, 108)',
    error600: 'rgb(240, 95, 87)',
    error700: 'rgb(238, 75, 66)',
    error800: 'rgb(236, 55, 45)',
    error900: 'rgb(212, 50, 41)',
    success25: 'rgb(230, 241, 239)',
    success50: 'rgb(204, 227, 223)',
    success100: 'rgb(179, 213, 207)',
    success200: 'rgb(153, 199, 191)',
    success300: 'rgb(128, 185, 175)',
    success400: 'rgb(102, 171, 158)',
    success500: 'rgb(77, 157, 142)',
    success600: 'rgb(51, 143, 126)',
    success700: 'rgb(26, 129, 110)',
    success800: 'rgb(0, 115, 94)',
    success900: 'rgb(0, 104, 85)',
    blue10: 'rgb(244, 248, 254)',
    blue15: 'rgb(237, 243, 254)',
    blue25: 'rgb(218, 232, 253)',
    blue50: 'rgb(206, 218, 237)',
    blue100: 'rgb(182, 200, 227)',
    blue200: 'rgb(157, 181, 218)',
    blue300: 'rgb(133, 163, 209)',
    blue400: 'rgb(108, 145, 200)',
    blue500: 'rgb(84, 126, 191)',
    blue600: 'rgb(59, 108, 181)',
    blue700: 'rgb(35, 89, 172)',
    blue800: 'rgb(10, 71, 163)',
    blue900: 'rgb(9, 64, 147 )',
    blueHint: 'rgb(218, 232, 253)',
    yellow25: 'rgb(255, 250, 230)',
    yellow100: 'rgb(254, 241, 180)',
    yellow400: 'rgb(252, 227, 106)',
    yellow800: 'rgb(250, 209, 6)',
    yellow900: 'rgb(225, 188, 5)',
    yellow950: 'rgb(150, 125, 4)',
    turquoise25: 'rgba(224, 248, 251, 1)',
    turquoise50: 'rgba(194, 241, 246, 1)',
    turquoise700: 'rgb(153, 232, 240)',
    turquoise800: 'rgb(0, 197, 218 )',
    turquoise900: 'rgb(0, 158, 174 )',
    pink50: 'rgb(252, 237, 246)',
    pink600: 'rgba(255, 151, 212, 1)',
    pink800: 'rgba(225, 84, 167, 1)',
  },
  learnerStatusColors: {
    ASSESSMENTBorder: 'rgb(239,106,1)',
    ASSESSMENTOutline: 'rgb(239,106,1)',
    ISEBOutline: avatarBorderByExamCode.ISEB,
    ISEBBorder: avatarBorderByExamCode.ISEB,
    CLASSWORKBorder: 'rgb(10,71,163)',
    CLASSWORKOutline: 'rgb(10,71,163)',
    DEEPREADINGBorder: 'rgb(26,129,110)',
    DEEPREADINGOutline: 'rgb(26,129,110)',
    DEEP_WRITEROutline: 'rgb(239,106,1)',
    DEEP_WRITERBorder: 'rgb(239,106,1)',
    EXAMBordere: 'rgb(239,106,1)',
    EXAMOutline: 'rgb(239,106,1)',
    grey50Outline: 'rgba(240, 244, 248)',
    HOMEWORKBorder: 'rgb(0,158,174)',
    HOMEWORKOutline: 'rgb(0,158,174)',
    ONBOARDINGBorder: 'rgb(247, 181, 128)',
    ONBOARDINGOutline: 'rgb(239, 106, 1)',
    RELAXINGBorder: 'rgb(153, 232, 240)',
    RELAXINGOutline: 'rgb(0, 197, 218)',
    STUDY_MODULEBorder: 'rgb(10,71,163)',
    STUDY_MODULEOutline: 'rgb(10,71,163)',
    STUDY_HALLBorder: 'rgb(10,71,163)',
    STUDY_HALLOutline: 'rgb(10,71,163)',
    STUDYINGBorder: 'rgb(247, 181, 128)',
    STUDYINGOutline: 'rgb(239, 106, 1)',
    TESTINGBorder: 'rgb(0, 166, 136)',
    TESTINGOutline: 'rgb(0, 115, 94)',
  },
  subjectRating: {
    defaultBackground: 'rgb(255, 255, 255)',
    defaultBorder: 'rgb(222, 221, 227)',
    dislikeBackground: 'rgb(253, 218, 218)',
    dislikeBorder: 'rgb(247, 69, 69)',
    neutralBackground: 'rgb(254, 237, 205)',
    neutralBorder: 'rgb(252, 179, 44)',
    likeBackground: 'rgb(219, 233, 229)',
    likeBorder: 'rgb(76, 144, 127)',
  },
};
