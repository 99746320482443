import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { fetchUserProfile } from '@/store/user-profile/queries';
import { useAuthContext } from '../AuthContext';
import { Action, initialState } from './types';

const reducer = (state: any, action: Action): any => {
  switch (action.type) {
    case 'INIT':
      return { ...action.payload };
    case 'SET_GIVEN_NAME':
      return { ...state, givenName: action.payload };
    case 'SET_SURNAME':
      return { ...state, surname: action.payload };
    case 'SET_DOB':
      return { ...state, dob: action.payload };
    case 'SET_REFERRAL_CODE':
      return { ...state, referralCode: action.payload };
    case 'SET_TARGET_SCHOOLS':
      return { ...state, targetSchools: action.payload };
    case 'SET_IS_ENGLISH_SECOND_LANGUAGE':
      return { ...state, isEnglishSecondLanguage: action.payload };
    case 'SET_INTERESTS':
      return { ...state, interests: action.payload };
    case 'SET_NOTIFICATIONS':
      return { ...state, notifications: action.payload };
    case 'SET_USERNAME':
      return { ...state, username: action.payload };
    case 'SET_GUARDIAN_LEARNERS':
      return { ...state, learners: action.payload } as any;
    case 'SET_IS_HOMESCHOOLED':
      return { ...state, isHomeschooled: action.payload };
    case 'UPDATE_BEST_STREAK':
      return { ...state, bestStreak: action.payload };
    case 'UPDATE_WEEKLY_STREAKS':
      return {
        ...state,
        streaks: action.payload,
      };
    case 'UPDATE_PROFILE_PICTURE':
      return { ...state, profilePicture: action.payload };
    case 'UPDATE_STUDENT_ZPD':
      return { ...state, zpd: action.payload };
    default:
      return state;
  }
};

type Props = {
  children: React.ReactNode;
};

const UserProfileContext = createContext<
  | {
      state: any;
      dispatch: React.Dispatch<Action>;
    }
  | undefined
>(undefined);

export const UserProfileProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { auth } = useAuthContext();

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  const currentUserQuery = fetchUserProfile(auth.isAuthenticated, auth.userId);

  // if (auth.isAuthenticated) {
  //   console.log('stt usrid', !state.userId);
  //   if (currentUserQuery.data.userId?.length > 0 && !state.userId) {
  //     dispatch({ type: 'INIT', payload: currentUserQuery.data });
  //   }
  // }

  useEffect(() => {
    if (auth.userId === currentUserQuery.data.userId)
      dispatch({ type: 'INIT', payload: currentUserQuery.data });
    // Add Logic //
  }, [currentUserQuery.data.userId, currentUserQuery.data?.updatedAt]);

  // if (currentUserQuery.isLoading || currentUserQuery.isFetching) {
  //   return <div>LOADING</div>;
  // }

  if (currentUserQuery.isError) {
    return <div />;
  }

  return (
    // REPLACE WITH RQ DATA INSTEAD OF USEMEMO & REDUCER
    <UserProfileContext.Provider value={contextValue}>
      {children}
    </UserProfileContext.Provider>
  );
};

export const useUserProfileContext = () => {
  const context = useContext(UserProfileContext);
  if (context === undefined) {
    throw new Error(
      'useUserProfileContext must be used within a UserProfileProvider'
    );
  }
  return context;
};
